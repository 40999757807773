import dataLayerPush from '../../GA4/utils/dataLayerPush';
import addToCart from './addToCart';
import search from './search';
import viewItem from './viewItem';
import category from './category';
import subCategory from './subCategory';
var events = {
  pageview: [dataLayerPush(search), dataLayerPush(category), dataLayerPush(subCategory)],
  'product:addToCart': [dataLayerPush(addToCart)],
  'product:details': [dataLayerPush(viewItem)]
};
export default events;