var search = function search(_ref) {
  var _data$search, _data$search$products;
  var data = _ref.data,
    structure = _ref.structure;
  var siteId = structure == null ? void 0 : structure.id;
  if (siteId === 'search' && (data == null ? void 0 : (_data$search = data.search) == null ? void 0 : (_data$search$products = _data$search.products) == null ? void 0 : _data$search$products.length)) {
    var _structure$route, _structure$cookies, _structure$cookies$ac, _data$search2, _data$search3, _data$search3$paginat;
    var term = (structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.term) || null;
    var userId = (structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id) || null;
    var attributionToken = (data == null ? void 0 : (_data$search2 = data.search) == null ? void 0 : _data$search2.attributionToken) || null;
    var offset = data == null ? void 0 : (_data$search3 = data.search) == null ? void 0 : (_data$search3$paginat = _data$search3.pagination) == null ? void 0 : _data$search3$paginat.size;
    var _data$search4 = data == null ? void 0 : data.search,
      products = _data$search4.products;
    var productDetails = products.map(function (product) {
      return {
        product: {
          id: product.variationId
        }
      };
    });
    return {
      cloud_retail: {
        attributionToken: attributionToken,
        eventType: 'search',
        offset: offset,
        productDetails: productDetails,
        searchQuery: term,
        userInfo: {
          userId: userId
        }
      },
      event: 'cloud_retail'
    };
  }
  return null;
};
export default search;